import React from "react";
import { Container, Link } from "@mui/material";
import styled from "@emotion/styled";
import LockerMenu from "../../LockerMenu";
import {
  AbsoluiteImage,
  CustomGrid,
  DataImage,
  SectionHeading,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import heroHeading from "../../../../assets/images/heroHeading.png";
import heroImage from "../../../../assets/images/heroImage.png";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: calc(100dvh - 35px);
  width: 100%;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const HeroSection = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "5px",
          display: "grid",
          placeItems: "center",
          background:
            "linear-gradient(to right,#9945FF,#9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B)",
        }}
      >
        <Paragraph
          padding="0px 0"
          sx={{ fontSize: { xs: "12px", sm: "14px" } }}
        >
          CA: 5Wd8ZxLHoameCY8fqy7CNFLNB5jX5i4USmTSKbDXFbsE
        </Paragraph>
      </div>
      <ContinerWraper maxWidth="xxl" id="home">
        <LockerMenu />
        <AbsoluiteImage
          mw="50%"
          mw1440="45%"
          b="20px"
          r="0%"
          disp599="none"
          // b='0%'
          src={heroImage}
        />
        <InnerContinerWraper>
          <CustomGrid
            container
            p="10px 0"
            p599="10px 0"
            fd="column"
            ai="start"
            jc="start"
          >
            <CustomGrid item xs={12} sm={7} jc="start">
              <DataImage
                disp="none"
                disp599="block"
                mw599="100%"
                src={heroImage}
              />
            </CustomGrid>
            <CustomGrid item xs={12} sm={7} jc="start">
              <DataImage
                data-aos="zoom-in"
                mw="80%"
                mw599="80%"
                src={heroHeading}
              />
            </CustomGrid>
            <CustomGrid
              container
              item
              xs={12}
              sm={7}
              md={4}
              lg={4}
              ai="start"
              fd="column"
              g="15px"
            >
              <Paragraph
              fontFamily="YuGothibold"
                sx={{ fontSize: { xs: "20px", sm: "22px" } }}
                padding="10px 0 0"
                data-aos="zoom-in"
              >
                Things Are Heating Up In The Sol Chain. 
                The Sol Dragon Is Waking Up From Its Slumber!
              </Paragraph>
              <Paragraph padding="0px 0" data-aos="zoom-in">
              Just as Tsuka revitalized Ethereum, Sol Dragon is here to bring 
              a new dawn to Solana, and breathe new life into the blockchain 
              and his arrival could not be at a more perfect time as 
              we approach the year of the dragon!
              </Paragraph>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Link href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5Wd8ZxLHoameCY8fqy7CNFLNB5jX5i4USmTSKbDXFbsE&fixed=in" target="blank">
                  <CustomButton>Buy Now</CustomButton>
                </Link>
                <Link href="https://dexscreener.com/solana/cnmls5ma9khsa1nnzezc5lziac2sah62jmuddtzq4gtt" target="blank">
                  <CustomButton bgc="#000" b="1px solid #fff">
                    View Chart
                  </CustomButton>
                </Link>
              </div>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HeroSection;
