import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import FeatureCard from "../../cards";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import FeatureImage from '../../../../assets/images/featureimg.png';

const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 40px 0px !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0px !important;
  z-index: 1;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const Features = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="features">
        <InnerContinerWraper>
          {/* <Container maxWidth='lg'> */}
          <Grid container>
            <Grid item xs={12} sm={12} md={6} p={1} order={{ xs: '2', sm: '2', md: '1' }}>
              <Grid container>
                <CustomGrid jc599='none' item xs={12} sm={6} md={6} p='0px 16px' data-aos="zoom-in">
                  <FeatureCard
                    tag='Huge Community'
                    data='Join the dynamic force of Sol Dragon ($SOLD). Our massive community not only amplifies collective wisdom but propels the project forward, fostering an environment of shared success and growth.'
                  />
                </CustomGrid>
                <Grid item xs={12} sm={6} md={6} px={2} data-aos="zoom-in">
                  <FeatureCard
                    tag='Liquidity Burnt'
                    data='
                    Sol Dragon ($SOLD) ensures a secure ecosystem by burning liquidity,
                    eliminating any possible access to the
                    LP pool hence bolstering the tokens stability 
                    and making it a safe investment for all.'
                    />
                  <FeatureCard
                    tag='Contract Renounced'
                    data='
                    In the spirit of transparency and decentralization, 
                    Sol Dragon reinforces its communitys
                    trust by renouncing its contract.The community takes 
                    the lead, shaping a secure and community-driven future.
                    '
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6} p={1} order={{ xs: 1, sm: '1', md: '2' }}>
              <SectionHeading
              data-aos="fade-up"
                ta='left'
                fs1175='40px'
                fs1024='40px'
                fs="40px"
                ta599='center'
              >
                Made For <br />
                Mass Adoption.
              </SectionHeading>
              <CustomGrid item xs={12} p='50px 0px'>
                <DataImage mw='400px' src={FeatureImage} />
              </CustomGrid>
            </Grid>
          </Grid>
          {/* </Container> */}
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Features;
