import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import footerImage from "../../../../assets/images/footerImage.png";
import footerCardImage from "../../../../assets/images/footerCardImage.png";
import { CustomGrid, DataImage } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import Heading from "../../../../assets/images/heroHeading.png";
import { MenuLink } from "../../LockerMenu/styles";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";

const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  background-image: url(${footerImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* mix-blend-mode: luminosity; */
  padding: 50px 0 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  z-index: 1;
  display: grid;
  place-items: center;
  @media (max-width: 599px) {
  }
`;

const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.b ? props.b : "10px")};
  left: ${(props) => (props.r ? props.r : "40px")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  display: ${(props) => (props.disp ? props.disp : "")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (min-width: 1440px) {
    max-width: ${(props) => props.mw1440};
  }
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "80%")};
    padding: ${(props) => props.m599};
    display: ${(props) => (props.disp599 ? props.disp599 : "")};
  }
`;
const Footer = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="footer">
      <InnerContinerWraper>
        <CustomGrid container>
          <CustomGrid
            m="40px 0 0"
            fd599="column"
            xs={12}
            jc="end"
            sx={{
              background: "#000",
              position: "relative",
              minHeight: "250px",
              borderRadius: "20px",
            }}
          >
            <CustomGrid
              container
              item
              m="20px 0 0"
              xs={12}
              sm={10}
              sx={{
                background:
                  "linear-gradient(to right,#9945FF,#9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B)",
                minHeight: "calc(100% + 30px)",
                minWidth: "calc(100% + 30px)",
                position: "absolute",
                filter: "blur(20px)",
                opacity: "0.7",
                borderRadius: "20px",
                zIndex: "-1",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-55%)",
              }}
            ></CustomGrid>
            <DataImage
              disp="none"
              disp599="block"
              mw599="80%"
              src={footerCardImage}
            />
            <AbsoluiteImage
              mw="35%"
              disp599="none"
              mw999="45%"
              src={footerCardImage}
            />
            <CustomGrid item xs={12} sm={6} fd="column" ai="start" p="30px 0">
              <Paragraph
                fontFamily="YuGothibold"
                sx={{
                  fontSize: { xs: "22px", sm: "24px" },
                  padding: {
                    xs: "30px 20px !important",
                    sm: "70px 100px 10px 0 !important",
                  },
                }}
                data-aos="zoom-in"
              >
                Grab Your SOLD And Moonwalk To The Moon – It's The Only Way To
                Go Forward
              </Paragraph>
              <Link href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=5Wd8ZxLHoameCY8fqy7CNFLNB5jX5i4USmTSKbDXFbsE&fixed=in" target="blank">
                <CustomButton sx={{ margin: { xs: "0 20px 40px", sm: "0 0px 40px" } }}>
                  Buy Now
                </CustomButton>
              </Link>
            </CustomGrid>
          </CustomGrid>
          <CustomGrid container m="60px 0 0" data-aos="fade-up">
            <CustomGrid
              item
              xs={12}
              jc="space-between"
              p="10px 0px 20px"
              ai="end"
              sx={{ borderBottom: "1px solid white" }}
            >
              <DataImage src={Heading} mw="130px" />
              <div style={{ display: "flex" }}>
                <MenuLink
                  href="https://t.me/soldragoncoin"
                  target="blank"
                  bgc="linear-gradient(to top,#9945FF,#9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B)"
                  br="40px"
                  p599="10px 10px 5px"
                  p="8px"
                  m="0px 5px"
                >
                  <DataImage mw="20px" src={tg} />
                </MenuLink>
                <MenuLink
                  href="https://twitter.com/soldragoncoin"
                  target="blank"
                  bgc="linear-gradient(to top,#9945FF,#9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B)"
                  br="40px"
                  p="8px"
                  p599="10px 10px 5px"
                  m="0px 5px"
                >
                  <DataImage mw="20px" src={x} />
                </MenuLink>
              </div>
            </CustomGrid>
            <CustomGrid item xs={12} p="20px 40px" p599="20px 0px">
              <Paragraph textAlign="center">
                Sol Dragon is a meme coin on the Solana blockchain. This is NOT
                investment advice. The coin is for entertainment and educational
                purposes only. The founders are not liable for any losses or
                damages. The market is volatile; invest at your own risk. No
                guarantees of profit or value retention. Research thoroughly
                before investing. By buying, you acknowledge the risks involved.
                Founders have no obligation to update information. Laws vary by
                jurisdiction; comply with local regulations. This disclaimer is
                subject to changes without notice.
              </Paragraph>
            </CustomGrid>
            <CustomGrid item xs={12} p="0px 0px 20px">
              <Paragraph textAlign="center">
                Copyright © 2023 All Rights Reserved Sol Dragon
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </CustomGrid>
      </InnerContinerWraper>
    </ContinerWraper>
  );
};

export default Footer;
