import React from "react";
import { Box, Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import Screener from "../../../../assets/images/dexscreener.png";
import DexTool from "../../../../assets/images/dextool.png";
import SolScan from "../../../../assets/images/solscan.png";
import Raydium from "../../../../assets/images/raydium.png";
import PartnerBg from "../../../../assets/images/gradientshadow.png";
import AboutImage from "../../../../assets/images/aboutimage.png";
import Paragraph from "../../Paragraph";
import AboutImg1 from "../../../../assets/images/aboutimg1.png";
import CustomButton from "../../CustomBtn";

const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  display: grid;
  place-items: center;
  padding: 0px !important;
  z-index: 1;
  @media (max-width: 599px) {
  }
`;

const PartnerGrid = styled(Grid)`
  background-image: url(${PartnerBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 100px 50px;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  @media (max-width: 599px) {
    padding: 0px 20px;
    min-height: 100vh;
  }
`;
const BoxStyle = styled(Box)`
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: center;
  gap: 0px 10px;
  padding: 30px 10px;
  @media (max-width: 599px) {
    gap: 0px 20px;
  }
`;
const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <InnerContinerWraper>
          <Grid container>
            <Grid item xs={12}>
              <PartnerGrid item xs={12} md={12}>
                <BoxStyle>
                  <DataImage
                    m="2px 40px"
                    src={Raydium}
                    mw599="80px"
                    mw899="100px"
                    mw="120px"
                  />
                  <DataImage
                    m="2px 40px"
                    src={SolScan}
                    mw599="80px"
                    mw899="100px"
                    mw="120px"
                  />
                  <DataImage
                    m="2px 40px"
                    src={DexTool}
                    mw599="80px"
                    mw899="100px"
                    mw="120px"
                  />
                  <DataImage
                    m="2px 40px"
                    src={Screener}
                    mw599="80px"
                    mw899="100px"
                    mw="120px"
                  />
                </BoxStyle>
                {/* <Grid container item>
                <CustomGrid
                  jc599='center'
                  ai='center'
                  jc='center'
                  p='10px 20px'
                  item xs={6} sm={3} md={3}>
                  <DataImage m='10px' src={Raydium} mw899='100px' mw599='80px' mw='130px' />
                </CustomGrid>
                <CustomGrid
                  jc599='center'
                  ai='center'
                  jc='center'
                   item xs={6} sm={3} md={3}>
                  <DataImage m='10px' src={SolScan} mw899='100px' mw599='80px' mw='130px' />
                </CustomGrid>
                <CustomGrid
                  jc599='center'
                  ai='center'
                  jc='center
                   ' item xs={6} sm={3} md={3}>
                  <DataImage m='10px' src={DexTool} mw899='100px' mw599='80px' mw='130px' />
                </CustomGrid>
                <CustomGrid
                  jc599='center'
                  ai='center'
                  jc='center'
                  item xs={6} sm={3} md={3}>
                  <DataImage m='10px' src={Screener}mw899='100px'  mw599='80px' mw='130px' />
                </CustomGrid>
              </Grid> */}
              </PartnerGrid>
            </Grid>
          </Grid>
          <Grid container sx={{ padding: "5% 0px" }}>
            <CustomGrid
              item
              xs={12}
              sm={12}
              md={6}
              jc="center"
              ai="start !important"
              order={{ xs: 2, sm: 2, md: 1 }}
            >
              <DataImage src={AboutImage} mw="350px" mw599="200px" />
            </CustomGrid>
            <Grid
              item
              data-aos="fade-up"
              xs={12}
              sm={12}
              md={6}
              sx={{ padding: "30px 20px" }}
              order={{ xs: 1, sm: 1, md: 2 }}
            >
              <SectionHeading

                fs1175="40px"
                fs1024="30px"
                fs="40px"
                ta="left"
                p="10px 0px"
              >
                The Birth Of
              </SectionHeading>
              <DataImage src={AboutImg1} mw="200px" />
              <Paragraph padding="10px 0px 0px">
                Due to the recent rise of Solana, as the superior blockchain by volume,
                a supreme being awakens from within the lowest depths of the Solana realm.
                Behold the legendary Sol Dragon,
                the unofficial mascot of the Solana blockchain that is ready
                to stretch it's wings and take Solana to even greater heights
                that have never been seen!
              </Paragraph>
              <Paragraph padding="20px 0px 30px">
                The Sol Dragon acts as a guardian to the Solana ecosystem and
                embodies the strength and resilience to protect the throne of
                Solana and it's inhabitants for generations to come. Let the Sol
                Dragon's flame be the the light that guides you like the north
                star in the dark night sky.
              </Paragraph>
              <Link href="https://t.me/soldragoncoin" target="blank">
                <CustomButton b="1px solid white" bgc="none">
                  Join Telegram Community
                </CustomButton>
              </Link>
            </Grid>
          </Grid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
