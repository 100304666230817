import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { SectionHeading } from '../../Styles/style';

const BoxStyle = styled(Box)`
    /* background: ${props => props.backbg? props.backbg : 'linear-gradient( to top, #000000, #1C1C1C )'}; */
    min-height: ${props => props.minh? props.minh : '250px'};
    background-color:  ${props => props.backbg? props.backbg : '#0C0C0C'};
    /* max-width: ${props => props.mw? props.mw : '250px'} ; */
    border-radius: 20px 20px;
    padding: ${props => props.pd? props.pd : '30px 20px'};
    margin: 20px auto;
    @media (max-width: 899px){
        min-height: 200px;
    }
`
const Paragraph = styled(Typography)`
    /* font-family: poppins-regular; */
    color: white;
    text-align: ${props => props.ta? props.ta : 'left'};
    font-size: 12px;
    padding: 10px 0px;
    /* text-align: center; */
    margin: ${props => props.m? props.m : ''};
`
const SectionHeadingExt = styled(Typography)`
    background: linear-gradient( to right,#9945FF,#9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B);
    background-clip: text;
    color: transparent;
    font-family: YuGothibold;
    text-align: left;
    font-size: 22px;
    line-height: 1;
    padding: 0px;
`
const FeatureCard = (props) => {
    const{tag, data} = props;
    return(
        <BoxStyle  {...props}>
            <SectionHeadingExt {...props}>{tag}</SectionHeadingExt>
            <Paragraph {...props}>{data}</Paragraph>
        </BoxStyle>
    )
}

export default FeatureCard;