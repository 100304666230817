import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";

const CustomBtn = styled(Button)`
  background: ${(props) =>
    props.bgc
      ? props.bgc
      : "linear-gradient(to right,#9945FF,#9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B)"};
  font-size: ${(props) => (props.fs ? props.fs : "14px")};
  border-radius: 20px;
  min-width: ${(props) => (props.w ? props.w : "120px")};
  font-family: ${(props) => (props.fm ? props.fm : "")};
  margin: ${(props) => (props.m ? props.m : "0")};
  padding: ${(props) => (props.p ? props.p : "2px 10px")};
  color: ${(props) => (props.c ? props.c : "#ffffff")};
  border: ${(props) => (props.b ? props.b : "1px solid #000")};
  transition: 1s;
  text-transform: none;
  cursor: ${(props) => (props.cur ? props.cur : "pointer")};
  z-index: 1;
  :hover {
    color: ${(props) => (props.hc ? props.hc : "#ffffff")};
    background: ${(props) =>
      props.hbgc
        ? props.hbgc
        : "linear-gradient(to right,#9945FF,#9945FF,#8752F3,#5497D5,#43B4CA,#28E0B9,#19FB9B)"};
  }
  @media (max-width: 599px) {
    padding: ${(props) => (props.p ? props.p : "4px 20px")};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "12px")};
  }
`;

const CustomButton = (props) => {
  const { children } = props;
  return (
    <CustomBtn variant="filled" {...props}>
      {children}
    </CustomBtn>
  );
};

export default CustomButton;
